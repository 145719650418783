import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ChatHeader() {

    const activeChat = useSelector((state) => state.messenger.activeChat)
    
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className="chat-history-header border-bottom">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex overflow-hidden align-items-center">
                    <i className="ti ti-menu-2 ti-sm cursor-pointer d-lg-none d-block me-2"></i>
                    <div className="flex-shrink-0 avatar">
                        <span className="avatar-initial rounded-circle bg-label-success">
                            {activeChat && activeChat.conversation.firstUser.nickname.charAt(0)}{activeChat && activeChat.conversation.secondUser.nickname.charAt(0)}
                        </span>
                    </div>
                    <div className="chat-contact-info flex-grow-1 ms-2">
                        <h6 className="m-0">
                            <span className='text-success'>{activeChat && activeChat.conversation.firstUser.nickname} </span> 
                            &&
                            <span className='text-primary'> {activeChat && activeChat.conversation.secondUser.nickname}</span> 
                        </h6>
                        <small className="user-status text-muted">Арбитраж №{activeChat && activeChat.id}</small>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="dropdown d-flex align-self-center">
                        <button className="btn p-0" type="button" onClick={() => setShowMenu(true)} >
                            <i className="ti ti-dots-vertical"></i>
                        </button>
                        <div className={`dropdown-menu dropdown-menu-end drop-menu-chat ${showMenu === true && 'show'}`} onMouseOver={() => setShowMenu(true)} onMouseOut={() => setShowMenu(false)}>
                            <Link className="dropdown-item" to={"#"}>
                                <i className="ti ti-users me-2"></i>
                                test1 
                            </Link>
                            <Link className="dropdown-item" to={"#"}>
                                <i className="ti ti-history me-2"></i>
                                test2
                            </Link>
                            <Link className="dropdown-item" to={"#"}>
                                <i className="ti ti-photo me-2"></i>
                                test3
                            </Link>
                            <Link className="dropdown-item" to={"#"}>
                                <i className="ti ti-bucket me-2"></i>
                                test4
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}   