import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import { sendNotification } from "../components/SendNotification";

const initialState = {
    arbitrations: false,
    archives: false,
    activeChat: false,
    activeArchive: false,
    newMessage: '',
    message: false,
    error: false,
    amountActiveChat: false,
    loading: false,
    unreadedMessages: [],
}

const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthData = async () => {

    let token = await Cookies.get("jwt");

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
}

const getAdminId = async () => {

    let token = await Cookies.get("jwt");
    return jwtDecode(token).data;
}

export const getActiveArbitrage = createAsyncThunk(
    'messenger/getActiveArbitrage', async (data, { rejectWithValue, dispatch }) => {
        
        let adminId = await getAdminId();
        let config = await getAuthData();

        try {
            const res = await axios.get(BASE_URL + `/arbitrations?status=PENDING&adminId=${adminId}`, config)
            dispatch(setArbitrage(res.data));
            dispatch(setAmountActiveChat(res.data.length));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }   
    }
)

export const getArchive = createAsyncThunk(
    'messenger/getArchive', async (data, { rejectWithValue, dispatch }) => {
        
        let adminId = await getAdminId();
        let config = await getAuthData();

        try {
            const res = await axios.get(BASE_URL + `/arbitrations?status=CLOSED&adminId=${adminId}`, config)
            dispatch(setArchives(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }   
    }
)

export const getArbitrageByID = createAsyncThunk(
    'messenger/getArbitrageByID', async (id, { rejectWithValue, dispatch }) => {

        let config = await getAuthData();

        const res = await axios.get(BASE_URL + `/arbitration/${id}`, config)
        dispatch(setActiveChat(res.data));
    }
)

export const getArchiveByID = createAsyncThunk(
    'messenger/getArbitrageByID', async (id, { rejectWithValue, dispatch }) => {

        let config = await getAuthData();

        const res = await axios.get(BASE_URL + `/arbitration/${id}`, config)
        dispatch(setActiveArchive(res.data));
    }
)

export const MessangerSlice = createSlice({
    name: 'messenger',
    initialState,
    reducers: {
        setArbitrage: (state, action) => {
            state.arbitrations = action.payload;
        },
        setArchives: (state, action) => {
            state.archives = action.payload;
        },
        setActiveChat: (state, action) => {
            state.activeChat = action.payload;
        },
        setActiveArchive: (state, action) => {
            state.activeArchive = action.payload;
        },
        setAmountActiveChat: (state, action) => {
            state.amountActiveChat = action.payload;
        },
        setNewMessage: (state, action) => {
            state.newMessage = action.payload;
        },
        pullMyMessageToChat: (state, action) => {
            if (state.activeChat.conversationId === action.payload.conversationId){
                state.activeChat.conversation.messages = [ ...state.activeChat.conversation.messages, action.payload ]
            } else{
                sendNotification("success", "Новое сообщение", action.payload.text,);
                state.unreadedMessages.push(action.payload.conversationId);
            }
        },
        setUnreadedMessages: (state, action) => {
            state.unreadedMessages.push(action.payload);
        },
        setReadedChat: (state, action) => {
            state.unreadedMessages = action.payload;
        },
    }, 
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    if (action.payload === 'Нет активных чатов') {
                        state.error = 'Нет активных чатов';
                    } else {
                        state.error = action.payload;
                        sendNotification("danger", "🔴", action.payload);
                    }
                }
            );
    }
})

export const { 
    setArbitrage, 
    setActiveChat, 
    setNewMessage, 
    setUnreadedMessages, 
    pullMyMessageToChat, 
    setAmountActiveChat, 
    setArchives,
    setActiveArchive,
    setReadedChat
} = MessangerSlice.actions

export default MessangerSlice.reducer