import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";

import { sendNotification } from "../components/SendNotification";

const initialState = {
    loginData: false,
    loading: false,
    error: false,
    show2Fa: false,
    token: false,
    user: false,
    role: false,
}

const BASE_URL = process.env.REACT_APP_API_URL;

export const makeLoginRequest = createAsyncThunk(
    'admin/makeLoginRequest', 
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(BASE_URL + `/auth/login`, data, {headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}});
            if (res.data.user.role === 'admin') {
                dispatch(setRole(res.data.user.role));
                dispatch(setToken(res.data.token));
                dispatch(setUser(res.data.user));
            } else {
                sendNotification("danger", "🔴", 'недостаточно прав, доступ запрещен!');
            }
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            if (error.response.data.message === 'Введите 2фа') {
                sendNotification("success", "🟢", error?.response?.data?.message);
                dispatch(setShow2Fa(true));
            } else {
                return rejectWithValue(message);
            }
        }
    }
)  

export const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setLoginData: (state, action) => {
            state.loginData = { ...state.loginData, [action.payload.key]: action.payload.val };
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setShow2Fa: (state, action) => {
            state.show2Fa = action.payload;
        },
        setToken: (state, action) => {
            Cookies.set("jwt", action.payload, { expires: 1 });
            state.token = action.payload;
        },
        setUser: (state, action) => {
            Cookies.set("verificated", action.payload.verificated, { expires: 1 });
            Cookies.set("username", action.payload.nickname, { expires: 1 });
            state.user = action.payload;
            sendNotification("success", "🟢", `${action.payload.nickname}, вы вошли в аккаунт!`);
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                    sendNotification("danger", "🔴", action.payload);
                }
            );
    }
})

export const { setLoginData, setToken, setUser, setRole, setShow2Fa } = AdminSlice.actions

export default AdminSlice.reducer