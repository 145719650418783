import React, { createContext } from 'react'
import io from 'socket.io-client';
import { connect } from 'react-redux';
import Cookies from "js-cookie";

import { sendNotification } from "../components/SendNotification";
import { pullMyMessageToChat, setUnreadedMessages }  from '../features/MessangerSlice';

const WebSocketContext = createContext(null)

export { WebSocketContext }

const Websocket = ({ 
  children, 
  pullMyMessageToChat,
  setUnreadedMessages
 }) => {

    let socket;
    let ws;
    let token = Cookies.get('jwt');

    const sendMessage = (chatId, userId, message) => {
        socket.emit("sendMessage", {
          conversationId: chatId,
          senderId: userId,
          text: message,
        });
    }

    // const onTyping = (chatId, userId, recipientId, typing) => {
    //     socket.emit("onTyping", { conversationId: chatId, senderId: userId, recipientId, typing,
    //   });
    // }

    if (!socket) {
        
        socket = io('https://p2p.apex-group.io', {
          path: '/ws',
          auth: { token },
        });

        socket.on("getMessage", (message) => {
          if (window.location.pathname.startsWith('/messenger')) {
            pullMyMessageToChat(
              { 
                conversationId: `${message.conversationId}`, 
                createdAt: message.createdAt, 
                senderId: `${message.senderId}`, 
                text: message.text,
              }
            )
          } else {
            setUnreadedMessages(`${message.conversationId}`)
            sendNotification("success", "Новое сообщение", message.text,);
          }
        });

        // socket.on("isTyping", (typing) => {
        //   updateTyping(typing);
        // });

        ws = {
          socket: socket,
          sendMessage,
        //   onTyping
        }
    }

    return (
      <WebSocketContext.Provider value={ws}>
        {children}
      </WebSocketContext.Provider>
    )
}

export function mapDispatchToProps(dispatch) {
  return {
    pullMyMessageToChat: (message) => { dispatch(pullMyMessageToChat(message)) },
    setUnreadedMessages: (id) => { dispatch(setUnreadedMessages(id)) },
  };
}

export default connect(null, mapDispatchToProps)(Websocket)