import React from 'react';

import moment from 'moment';
import 'moment/locale/ru';

moment().locale('ru');

const ModalBalance = ({ data, show, modalClose }) => { 

    // const getStatus = (status) => {
    //     switch (status) {
    //         case "PENDING":
    //             return "открытая"
    //         case "CLOSED":
    //             return "закрытая"
    //         case "SUCCESS":
    //             return "успешная"
    //     }
    // }

    // const getDirection = (direction) => {
    //     switch (direction) {
    //         case "SELL":
    //             return "продажа"
    //         case "BUY":
    //             return "покупка"
    //     }
    // }

    // const getDirectionConfirmed = (user) => {        
    //     switch (true) {
    //         case user === data.orderOwner.id && data.offer.direction === "SELL" :
    //           return false
    //         case user !== data.orderOwner.id && data.offer.direction === "SELL" :
    //           return true
    //         case user === data.orderOwner.id && data.offer.direction === "BUY" :
    //           return true
    //         case user !== data.orderOwner.id && data.offer.direction === "BUY" :
    //           return false
    //     }
    // }

    return (
        <div className={`modal modal-style fade ${show === true ?'show' : 'd-none'}`}>
            <div className="modal-dialog modal-lg modal-simple modal-add-new-address">
                <div className="modal-content p-3 p-md-5">
                    <div className="modal-body p-0">
                        <button type="button" className="btn-x" onClick = {() => modalClose()}></button>
                        <div className="row">
                            <div className="text-center mb-4">
                                <h3 className="address-title mb-2">БАЛАНСЫ:</h3>
                                <p className="text-muted address-subtitle">Информация о балансах юзеров</p>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="card h-100">
                                    <div className="card-body pb-0" style={{position: 'relative'}}>
                                        <ul className="p-0 m-0">
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3 my-auto">
                                                    <span className="avatar-initial rounded bg-label-success"><i className="ti ti-user ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Владелец сделки:</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase text-success">offerOwner</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr variant="middle" sx={{ mb: 2, opacity: 0.4, backgroundColor: '#b6bee3' }} /> 
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Заморожено для этого ордера:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-info py-2">
                                                            <h6 className='mb-0 text-info'>UAH:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-uppercase text-info">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr variant="middle" sx={{ mb: 2, opacity: 0.4, backgroundColor: '#b6bee3' }} /> 
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Балансы:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-success py-2">
                                                            <h6 className='mb-0 text-success'>UAH:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-success py-2">
                                                            <h6 className='mb-0 text-success'>USDT:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-success py-2">
                                                            <h6 className='mb-0 text-success'>RUB:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Заморожено:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-secondary py-2">
                                                            <h6 className='mb-0'>UAH:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-secondary py-2">
                                                            <h6 className='mb-0'>RUB:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="card h-100">
                                    <div className="card-body pb-0" style={{position: 'relative'}}>
                                        <ul className="p-0 m-0">
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3 my-auto">
                                                    <span className="avatar-initial rounded bg-label-primary"><i className="ti ti-user ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Участник сделки:</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase text-primary">orderOwner</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr variant="middle" sx={{ mb: 2, opacity: 0.4, backgroundColor: '#b6bee3' }} /> 
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Заморожено для этого ордера:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-info py-2">
                                                            <h6 className='mb-0 text-info'>RUB:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-uppercase text-info">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr variant="middle" sx={{ mb: 2, opacity: 0.4, backgroundColor: '#b6bee3' }} /> 
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Балансы:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-primary py-2">
                                                            <h6 className='mb-0 text-primary'>RUB:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-primary py-2">
                                                            <h6 className='mb-0 text-primary'>USDT:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-primary py-2">
                                                            <h6 className='mb-0 text-primary'>EUR:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Заморожено:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-secondary py-2">
                                                            <h6 className='mb-0'>RUB:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className='row w-100'>
                                                    <div className="col-6 ">
                                                        <span className="badge bg-label-secondary py-2">
                                                            <h6 className='mb-0'>USDT:</h6>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <div className="user-progress ">
                                                            <strong className="text-modal text-uppercase">не готово</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-end mt-5 pb-0">
                                <button type="reset" 
                                    className="btn btn-label-secondary btn-reset waves-effect" 
                                    onClick = {() => modalClose()}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalBalance;