import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header'
import Chats from './components/Chats'
import ChatSettings from './components/ChatSettings'

import { getArchive, getArchiveByID, setActiveArchive } from '../../features/MessangerSlice'

import moment from 'moment';
import 'moment/locale/ru';

moment().locale('ru');

export default function ArchivePage() {

    const [loading, setLoading] = useState(true);
    const [showArchive, setShowArchive] = useState(false);

    const dispatch = useDispatch()

    const archives = useSelector((state) => state.messenger.archives)
    const activeArchive = useSelector((state) => state.messenger.activeArchive)
    const error = useSelector((state) => state.messenger.error)

    useEffect(() => {
        dispatch(getArchive());
        setLoading(false);
    }, [])

    const getClosedArbitration = (id) => {
        dispatch(getArchiveByID(id))
        setShowArchive(true)
    }

    return (
        <>
            <Header/>
            <div className="content-wrapper vh-100" style={{marginLeft: '260px'}}>
                <div className="container-xxl flex-grow-1 pt-3">
                    {!activeArchive ?
                        <h3 className="mt-3 mb-4">Закрытые заявки на арбитраж</h3>
                    : 
                        <button onClick={() => {setShowArchive(false); dispatch(setActiveArchive(false));}} className='non-style'>
                            <h5 className="text-primary mb-2 p-1" >
                                <i className="menu-icon tf-icons ti-lg ti ti-chevron-left"></i>
                                Вернуться в архив
                            </h5>
                        </button>
                    }
                    <div className="row">
                        {loading === false ?
                            error && error === 'Арбитражей нет' ? (
                                <ul className="list-unstyled chat-history">
                                    <li className="chat-message chat-message-right text-center" style={{justifyContent: 'center', marginTop: '200px', marginBottom: '200px'}}>
                                        <div className="d-flex overflow-hidden">
                                            <div className="chat-message-wrapper flex-grow-1">
                                                <div className="chat-message-text">
                                                <button type="button" className="btn btn-lg btn-label-secondary waves-effect" disabled>нет закрытых арбитражей!</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                showArchive === true && activeArchive ? (
                                    <div className="app-chat card overflow-hidden h-100 ps-0">
                                        <div className="row g-0 h-100">
                                            <Chats archive={true} data={activeArchive}/>
                                            <ChatSettings archive={true}/>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-xl-12 col-md-12 mb-4">
                                        <div className="card h-100">
                                            <div className="card-header d-flex justify-content-between">
                                                <div className="card-title mb-0">
                                                    <h5 className="mb-0">Все закрытые заявки</h5>
                                                    <small className="text-muted">{ archives.length} шт.</small>
                                                </div>
                                                <div className="dropdown">
                                                    <button className="btn p-0" type="button" >
                                                        <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-end" >
                                                        <a className="dropdown-item" href="#">Refresh</a>
                                                        <a className="dropdown-item" href="#">Download</a>
                                                        <a className="dropdown-item" href="#">View All</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body mb-3">
                                                <ul className="p-0 m-0">
                                                    {archives && archives.map((item, index) => (
                                                        <li className="mt-3 d-flex justify-content-between border-table" key={index}>
                                                            <div className="d-flex justify-content-between w-100 flex-wrap ">
                                                                <h6 className="mb-0 ms-3 text-uppercase">Арбитраж №{item.id}</h6>
                                                                <div className="d-flex">
                                                                    <p className="mb-0 fw-medium">Ордер №{item.orderId}</p>
                                                                    <p className="ms-3 text-success mb-0"></p>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <h6 className="m-0">
                                                                        <span className='text-success'>{item.firstUser.nickname} </span> 
                                                                        &&
                                                                        <span className='text-primary'> {item.secondUser.nickname}</span> 
                                                                    </h6>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <p className="ms-3 mb-0">{moment(item.createdAt).format('DD.MM.YYYY, HH:mm')}</p>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <button 
                                                                        className="btn btn-sm btn-outline-info btn-reset waves-effect" 
                                                                        onClick = {() => getClosedArbitration(item.id)}
                                                                    >
                                                                        Просмотреть
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        : 
                            <div className="col-4 m-auto mt-5">
                                <button type="submit" className="mt-5 btn btn-label-primary btn-lg d-grid waves-effect waves-light mx-auto">
                                    <span>
                                        <span className="spinner-grow me-1" role="status" aria-hidden="true"></span> 
                                        Загрузка...
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}    