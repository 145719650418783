import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Cookies from "js-cookie";

import { setActiveArchive, setActiveChat } from '../features/MessangerSlice';

const Header = () => { 

    const dispatch = useDispatch()

    const amountMessage = useSelector((state) => state.messenger.amountActiveChat)
    const amountVerifi = useSelector((state) => state.verification.amountVerification)
    
    const logout = () => {
        Cookies.remove("jwt");
        Cookies.remove("adminId");
        window.location.reload();
    };

    const cleanUp = () => {
        dispatch(setActiveArchive(false));
        dispatch(setActiveChat(false));
    };

    const renderActiveClass = (type) => {
        if (window.location.pathname === type) {
            return 'active';
        }
    };

    return(
        <aside className="layout-menu menu-vertical menu bg-menu-theme" style={{height: '100%', position: 'fixed'}} >
            <div className="app-brand demo">
                <Link to={'/'} className="app-brand-link">
                    <span className="app-brand-logo demo">
                        <img src={require('../img/logo2.png')} className='logo-img'/>
                    </span>
                </Link>
            </div>
            <ul className="menu-inner py-1 ps ps--active-y">
                <li className="menu-header small text-uppercase">
                    <span className="menu-header-text">Кабинет админа</span>
                </li>
                <li className={`menu-item ${renderActiveClass('/')}`}>
                    <Link to={`/`} className="menu-link">
                        <i className="menu-icon tf-icons ti ti-photo-sensor-2"></i>
                        <div>Верификация</div>
                        <div className="badge bg-primary rounded-pill ms-auto">{amountVerifi && amountVerifi}</div>
                    </Link>
                </li>
                <li className="menu-header small text-uppercase">
                    <span className="menu-header-text">Арбитраж</span>
                </li>
                <li className={`menu-item ${renderActiveClass('/messenger')}`} onClick={() => cleanUp()}>
                    <Link to={`/messenger`} className='menu-link'>
                        <i className="menu-icon tf-icons ti ti-messages"></i>
                        <div>Заявки</div>
                        <div className="badge bg-primary rounded-pill ms-auto">{amountMessage && amountMessage}</div>
                    </Link>
                </li>
                <li className={`menu-item ${renderActiveClass('/archive')}`}  onClick={() => cleanUp()}>
                    <Link to={`/archive`} className="menu-link">
                        <i className="menu-icon tf-icons ti ti-archive"></i>
                        <div>Архив</div>
                    </Link>
                </li>
                <li className="menu-header small text-uppercase">
                    <span className="menu-header-text">Другое</span>
                </li>
                <li className={`menu-item ${renderActiveClass('/statistics')}`}>
                    <Link to={`/statistics`} className="menu-link">
                        <i className="menu-icon tf-icons ti ti-chart-bar"></i>
                        <div>Статистика</div>
                    </Link>
                </li>
                <li className="menu-item">
                    <a href="#" className="menu-link" onClick = {() => logout()}>
                        <i className="menu-icon tf-icons ti ti-text-wrap-disabled"></i>
                        <div>Выход</div>
                    </a>
                </li>
            </ul>
        </aside>
    )
}
       
export default Header;