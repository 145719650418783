import React from 'react';

const SimpleModal = ({title, text, btnSuccess, btnClose, onClickFunct, show, modalClose}) => { 
    return(
        <div className={`modal modal-style fade ${show === true ?'show' : 'd-none'}`}>
            <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                <div className="modal-content p-3 p-md-5">
                    <div className="modal-body">
                        <button type="button" className="btn-close" onClick = {() => modalClose()}></button>
                        <div className="text-center mb-4">
                            <h3 className="mb-2">{title}</h3>
                            <h5 className="text-danger mt-5">{text}</h5>
                        </div>
                        <div className="col-12 text-center mt-5">
                            <button type="submit" 
                                className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                                onClick={() => { onClickFunct(); modalClose()}}
                            >
                                {btnSuccess}
                            </button>
                            <button type="reset" 
                                className="btn btn-label-secondary btn-reset waves-effect" 
                                onClick = {() => modalClose()}
                            >
                                {btnClose}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SimpleModal;