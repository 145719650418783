import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import AuthCode from 'react-auth-code-input';
import { isEmpty } from 'lodash';

import { setLoginData, makeLoginRequest } from '../../features/AdminSlice'

export default function LogInPage() {

    const [showPass, setShowPass] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalid2fa, setInvalid2fa] = useState(false);

    const dispatch = useDispatch()

    const loginData = useSelector((state) => state.admin.loginData)
    const loading = useSelector((state) => state.admin.loading)
    const show2Fa = useSelector((state) => state.admin.show2Fa)
    const token = useSelector((state) => state.admin.token)
    const user = useSelector((state) => state.admin.user)
    const role = useSelector((state) => state.admin.role)

    const handleSubmit = (e) => {
        if (e) { e.preventDefault(); }
        if (!isEmpty(loginData.email)) {
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
            return;
        }
        if (!isEmpty(loginData.password)) {
            setInvalidPassword(false);
        } else {
            setInvalidPassword(true);
            return;
        }
        if (show2Fa === true) {
            if (!isEmpty(loginData.code)) {
                setInvalid2fa(false);
            } else {
                setInvalid2fa(true);
                return;
            }
        }   
        dispatch(makeLoginRequest(loginData));
      };
      
    return (
        <>
            {role === 'admin' && token && user && <Navigate push to={{pathname: '/'}}/>}
            <div className='dark-style'>
                <div className="authentication-wrapper authentication-cover authentication-bg">
                    <div className="authentication-inner row">
                        <div className="d-none d-lg-flex col-lg-7 p-0">
                            <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                                <img src={require('../../img/cryptoman.png')} style={{opacity: '0.7', transform: 'scaleX(-1)'}} className="img-fluid my-5 auth-illustration"/>
                                <img src={require('../../img/bg-shape-image-dark.png')} className="platform-bg" />
                            </div>
                        </div>
                        <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                            <div className="w-px-400 mx-auto">
                                <div className="app-brand mb-4">
                                    <Link to="#" className="app-brand-link gap-2">
                                        <img src={require('../../img/logo2.png')} width={150}/>
                                    </Link>
                                </div>
                                <h3 className="mb-1">Вход</h3>
                                <p className="mb-4 text-muted fm-roboto">Войдите в кабинет для начала работы</p>
                                <form className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit}>
                                    <div className="mb-3 fv-plugins-icon-container">
                                        <div className="mb-3">
                                            <label className="form-label">Ваш Email</label>
                                            <input type="email" 
                                                name='email'
                                                className="form-control" 
                                                placeholder="user123@mail.com"
                                                onChange={(e) => dispatch(setLoginData({key: e.target.name, val: e.target.value}))}
                                            />
                                            {invalidEmail === true && <small className='text-danger'>Email не может быть пустым.</small>}
                                        </div>
                                    </div>
                                    <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                        <div className="form-password-toggle">
                                            <label className="form-label">Ваш Пароль</label>
                                            <div className="input-group">
                                                <input 
                                                    type={showPass ? 'text' : 'password'}
                                                    name='password'
                                                    className="form-control"  
                                                    placeholder="*********"
                                                    onChange={(e) => dispatch(setLoginData({key: e.target.name, val: e.target.value}))}
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={() => setShowPass(!showPass)}>
                                                    {showPass === true ? (
                                                        <i className="ti ti-eye"></i>
                                                    ) : (
                                                        <i className="ti ti-eye-off"></i>
                                                    )}
                                                </span>
                                            </div>
                                            {invalidPassword === true && <small className='text-danger'>Пароль не может быть пустым.</small>}
                                        </div>
                                    </div>
                                    {show2Fa === true &&
                                        <>
                                            <label className="form-label">Введите код с аутентификатора</label>
                                            <AuthCode
                                                onChange={(value) => dispatch(setLoginData({key: 'code', val: value}))}
                                                allowedCharacters="numeric"
                                                length={6}
                                                name="code"
                                                placeholder="x"
                                                inputClassName='code'
                                                containerClassName='codeinput'
                                                autocomplete={false}
                                            />
                                            {invalid2fa === true && <small className='text-danger mb-3'>Код не может быть пустым.</small>}
                                        </>
                                    }
                                    {loading === true ? (
                                        <button type="submit" className="btn btn-label-primary btn-lg d-grid w-100 waves-effect waves-light mt-5">
                                            <span>
                                                <span className="spinner-grow me-1" role="status" aria-hidden="true"></span> 
                                                Загрузка...
                                            </span>
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary btn-lg d-grid w-100 waves-effect waves-light mt-5">
                                            Войти
                                        </button>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    );
}    