import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Header'
import ChatList from './components/ChatList'
import Chats from './components/Chats'
import ChatSettings from './components/ChatSettings'

import { getActiveArbitrage } from '../../features/MessangerSlice'

export default function ChatPage() {

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()

    const arbitrations = useSelector((state) => state.messenger.arbitrations)
    const error = useSelector((state) => state.messenger.error)

    useEffect(() => {
        dispatch(getActiveArbitrage());
        setLoading(false);
    }, [])

    return (
        <>
            <Header/>
            <div className="content-wrapper vh-100" style={{marginLeft: '260px'}}>
                <div className="container-xxl flex-grow-1 pt-3">
                    <div className="app-chat card overflow-hidden h-100">
                        <div className="row g-0 h-100">
                            {loading === false ?
                                error && error === 'Арбитражей нет' ? (
                                    <ul className="list-unstyled chat-history">
                                        <li className="chat-message chat-message-right text-center" style={{justifyContent: 'center', marginTop: '200px', marginBottom: '200px'}}>
                                            <div className="d-flex overflow-hidden">
                                                <div className="chat-message-wrapper flex-grow-1">
                                                    <div className="chat-message-text">
                                                    <button type="button" className="btn btn-lg btn-label-secondary waves-effect" disabled>Нет активных чатов!</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                    <>
                                        <ChatList />
                                        <Chats />
                                        <ChatSettings/>
                                    </>
                                )
                            : 
                                <div className="col-4 m-auto">
                                    <button type="submit" className="btn btn-label-primary btn-lg d-grid waves-effect waves-light mx-auto">
                                        <span>
                                            <span className="spinner-grow me-1" role="status" aria-hidden="true"></span> 
                                            Загрузка...
                                        </span>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    