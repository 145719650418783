import React from 'react';

import Header from '../../components/Header'

export default function Statistics() {

    return (
        <>
            <Header/>
            <div className="content-wrapper vh-100" style={{marginLeft: '260px'}}>
                <div className="container-xxl flex-grow-1 pt-3">
                    <h3 className="mt-3 mb-4">Статистика</h3>
                    <div className="row">


                        <div className="col-lg-4 col-12 mb-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <small className="d-block mb-1 text-muted">Заявки на арбитраж</small>
                                        <p className="card-text text-success">82%</p>
                                    </div>
                                    <h4 className="card-title mb-1">1000 шт.</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="d-flex gap-2 align-items-center mb-2">
                                                <span className="badge bg-label-success p-1 rounded">
                                                    <i className="ti ti-check ti-xs"></i>
                                                </span>
                                                <p className="mb-0">Закрыто</p>
                                            </div>
                                            <h5 className="mb-0 pt-1 text-nowrap">82%</h5>
                                            <small className="text-muted">820 шт</small>
                                        </div>
                                        <div className="col-4">
                                            <div className="divider divider-vertical">
                                                <div className="divider-text">
                                                    <span className="badge-divider-bg bg-label-secondary">VS</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 text-end">
                                            <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
                                                <p className="mb-0">Обработка</p>
                                                <span className="badge bg-label-warning p-1 rounded">
                                                    <i className="ti ti-hourglass-high ti-xs"></i>
                                                </span>
                                            </div>
                                            <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">18%</h5>
                                            <small className="text-muted">180 шт</small>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                        <div className="progress w-100" style={{height: '8px'}}>
                                            <div className="progress-bar bg-success" style={{width: '82%'}} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                            <div className="progress-bar bg-warning" role="progressbar" style={{width: '18%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-8 col-12 mb-4">
                            <div className="card h-100">
                                <div className="card-header pb-0 d-flex justify-content-between mb-lg-n4">
                                    <div className="card-title mb-0">
                                        <small className="text-muted">Заявки на верификацию пользователей</small>
                                        <h5 className="mb-0">Верификация</h5>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn p-0" type="button">
                                            <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" >
                                            <a className="dropdown-item" href="#">View More</a>
                                            <a className="dropdown-item" href="#">Delete</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4 d-flex flex-column align-self-end">
                                            <div className="d-flex gap-2 align-items-center mb-2 pb-1 flex-wrap">
                                                <h2 className="mb-0">1203 шт</h2>
                                                <div className="badge rounded bg-label-success">65.7%</div>
                                            </div>
                                            <small>Количество заявок на верификацию за все время</small>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <div className="border rounded p-3 mt-4">
                                                <div className="row gap-4 gap-sm-0">
                                                    <div className="col-12 col-sm-4">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="badge rounded bg-label-success p-1">
                                                                <i className="ti ti-checkbox ti-sm"></i>
                                                            </div>
                                                            <h6 className="mb-0">Успешно</h6>
                                                        </div>
                                                        <h4 className="my-2 pt-1">791 шт 
                                                            <small className='text-muted'> 65.7%</small>
                                                        </h4>
                                                        <div className="progress w-75" style={{height: '4px'}}>
                                                            <div className="progress-bar bg-success" role="progressbar" style={{width: '65.7%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="badge rounded bg-label-warning p-1">
                                                                <i className="ti ti-clock ti-sm"></i>
                                                            </div>
                                                            <h6 className="mb-0">В процессе</h6>
                                                        </div>
                                                        <h4 className="my-2 pt-1">376 шт 
                                                            <small className='text-muted'> 31.2%</small>
                                                        </h4>
                                                        <div className="progress w-75" style={{height: '4px'}}>
                                                            <div className="progress-bar bg-warning" role="progressbar" style={{width: '31.2%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="badge rounded bg-label-danger p-1">
                                                                <i className="ti ti-ban ti-sm"></i>
                                                            </div>
                                                            <h6 className="mb-0">Отклонено</h6>
                                                        </div>
                                                        <h4 className="my-2 pt-1">36 шт 
                                                            <small className='text-muted'> 3.1%</small>
                                                        </h4>
                                                        <div className="progress w-75" style={{height: '4px'}}>
                                                            <div className="progress-bar bg-danger" role="progressbar" style={{width: '3.1%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    