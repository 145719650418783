import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";

import { sendNotification } from "../components/SendNotification";

const initialState = {
    newAmount: '',
    dataOrder: false,
    loading: false,
    error: false,
}

const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthData = async () => {
    let token = await Cookies.get("jwt");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
}

export const getOrderById = createAsyncThunk(
    'chats/getOrderById', async (data, { rejectWithValue, dispatch }) => {
        let config = await getAuthData();
        const res = await axios.get(BASE_URL + `/user/${data.userId}/order/${data.orderId}`, config)
        dispatch(setDataOrder(res.data));
    }
)

export const sendNewAmount = createAsyncThunk(
    'chats/sendNewAmount', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.patch(BASE_URL + `/arbitration/correction`, data, config);
            sendNotification("success", "🟢", 'Сумма ордера успешно изменена');
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }
    }
)

export const closeArbitration = createAsyncThunk(
    'chats/closeArbitration', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.patch(BASE_URL + `/arbitration/end`, data, config);
            sendNotification("success", "🟢", 'Арбитраж успешно закрыт');
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }
    }
)

export const ChatsSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        setNewAmount: (state, action) => {
            state.amount = action.payload;
        },
        setDataOrder: (state, action) => {
            state.dataOrder = action.payload;
        },
    }, 
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    if (action.payload === 'Нет активных чатов') {
                        state.error = 'Нет активных чатов';
                    } else {
                        state.error = action.payload;
                    }
                    sendNotification("danger", "🔴", action.payload);
                }
            );
    } 
})

export const { setNewAmount, setDataOrder } = ChatsSlice.actions

export default ChatsSlice.reducer