import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";

import { sendNotification } from "../components/SendNotification";

const initialState = {
    arbitrations: false,
    activeChat: false,
    amountVerification: false,
    error: false,
    loading: false,
}

const BASE_URL = process.env.REACT_APP_API_URL;


const getAuthData = async () => {

    let token = await Cookies.get("jwt");

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
}

// export const getActiveArbitrage = createAsyncThunk(
//     'verification/getActiveArbitrage', async (data, { rejectWithValue, dispatch }) => {
        
//         let config = await getAuthData();
//         let adminId = await Cookies.get("adminId");

//         try {
//             const res = await axios.get(BASE_URL + `arbitrations?status=${data.status}&adminId=${adminId}`, config)
//             dispatch(setArbitrage(res.data));
//             dispatch(setAmountActiveChat(res.data.length));
//         }   catch (error) {
//             const message = (error.response.data.message) || error.toString();
//             return rejectWithValue(message);
//         }   
//     }
// )


// export const getArbitrageByID = createAsyncThunk(
//     'verification/getArbitrageByID', async (id, { rejectWithValue, dispatch }) => {

//         let config = await getAuthData();

//         const res = await axios.get(BASE_URL + `arbitration/${id}`, config)
//         dispatch(setActiveChat(res.data));
//     }
// )

export const VerificationSlice = createSlice({
    name: 'verification',
    initialState,
    reducers: {
        // setArbitrage: (state, action) => {
        //     state.arbitrations = action.payload;
        // },
        // setActiveChat: (state, action) => {
        //     state.activeChat = action.payload;
        // },
    }, 
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                    if (action.payload === 'Нет активных чатов') {
                        state.error = 'Нет активных чатов';
                    } else {
                        state.error = action.payload;
                        sendNotification("danger", "🔴", action.payload);
                    }
                }
            );
    } 
})

export const { } = VerificationSlice.actions

export default VerificationSlice.reducer