import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {  BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import WebSocketProvider from './utils/WebSocket';

import Cookies from "js-cookie";
import { isEmpty } from 'lodash';

import './App.css';
import { store } from './store/store'

import LogInPage from './containers/login/LogInPage';

import Verification from './containers/verification/VerificationPage';

import HomePage from './containers/dashboard/HomePage';
import Statistics from './containers/dashboard/Statistics';

import ChatPage from './containers/messenger/index';
import ArchivePage from './containers/messenger/ArchivePage';

export default function App() {

  const PrivateWrapper =  ({ children }) => {
    
    if ( !isEmpty(Cookies.get("jwt")) ) {
      var user = true;
    } else {
      user = false;
    }
    return user ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route element={<PrivateWrapper />}>
            <Route exact path='/' element={<Verification/>}/>
            <Route exact path='/statistics' element={<Statistics/>}/>

            <Route exact path='/archive' element={<ArchivePage/>}/>
            <Route exact path="/messenger" element={<WebSocketProvider><ChatPage/></WebSocketProvider>} />
          </Route>
          <Route exact path="/login" element={<LogInPage/>} />
        </Routes>
      </Router>
    </Provider>
  );
}