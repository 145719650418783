import React, {useState} from 'react';

import moment from 'moment';
import 'moment/locale/ru';

moment().locale('ru');

const ModalOrder = ({ data, show, modalClose }) => { 

    const [historyShow, setHistoryShow] = useState(false);

    const getStatus = (status) => {
        switch (status) {
            case "PENDING":
                return "открытая"
            case "CLOSED":
                return "закрытая"
            case "SUCCESS":
                return "успешная"
        }
    }

    const getTimelineColor = (index) => {
        switch (index) {
            case 0:
                return "timeline-point-danger"
            case 1:
                return "timeline-point-warning"
            case 2:
                return "timeline-point-success"
            case 3:
                return "timeline-point-primary"
            default:
                return "timeline-point-dark"
        }
    }

    const getDirection = (direction) => {
        switch (direction) {
            case "SELL":
                return "продажа"
            case "BUY":
                return "покупка"
        }
    }

    const getDirectionConfirmed = (user) => {        
        switch (true) {
            case user === data.orderOwner.id && data.offer.direction === "SELL" :
              return false
            case user !== data.orderOwner.id && data.offer.direction === "SELL" :
              return true
            case user === data.orderOwner.id && data.offer.direction === "BUY" :
              return true
            case user !== data.orderOwner.id && data.offer.direction === "BUY" :
              return false
        }
    }

    return (
        <div className={`modal modal-style fade ${show === true ?'show' : 'd-none'}`}>
            <div className="modal-dialog modal-lg modal-simple modal-add-new-address">
                <div className="modal-content p-3 p-md-5">
                    <div className="modal-body p-0">
                        <button type="button" className="btn-x" onClick = {() => modalClose()}></button>
                        <div className="row">
                            <div className="text-center mb-4">
                                <h3 className="address-title mb-2">Ордер №{data.id}</h3>
                                <p className="text-muted address-subtitle">Информация об ордере</p>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="card h-100">
                                    <div className="card-body pb-0" style={{position: 'relative'}}>
                                        <ul className="p-0 m-0">
                                            {data.metadata.corrections ? 
                                                <>
                                                    <li className="d-flex">
                                                        <div className="avatar flex-shrink-0 me-3">
                                                            <span className="avatar-initial rounded bg-label-info"><i className="ti ti-abacus ti-sm"></i></span>
                                                        </div>
                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                            <h6 className="mb-0 text-muted">Сумма:</h6>
                                                            <div className="user-progress d-flex align-items-center gap-3">
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <strong className="text-modal text-uppercase">{data.amount}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {historyShow === true ?
                                                        <>
                                                            <button className="btn btn-sm btn-outline-danger waves-effect mt-2" onClick={() => setHistoryShow(false)}>
                                                                <i className="ti ti-x me-md-1 me-0"></i>
                                                                <span className="align-middle d-md-inline-block">Скрыть</span>
                                                            </button>
                                                            <div className='card p-3 my-3'>
                                                                <ul className="timeline ms-1 mb-0">
                                                                    {data.metadata.corrections && data.metadata.corrections.map( (item, index) => (
                                                                        <li className="timeline-item timeline-item-transparent ps-4" key={index}>
                                                                            <span className={`timeline-point ${getTimelineColor(index)}`}></span>
                                                                            <div className="timeline-event">
                                                                                <div className="timeline-header">
                                                                                    <h6 className="mb-0">Сума: {item.amount}{' '}{data.currency}</h6>
                                                                                    {index !== 0 &&
                                                                                        <small className="text-muted">
                                                                                            {moment(item.date).format('DD.MM.YYYY, HH:mm')}
                                                                                        </small>
                                                                                    }
                                                                                </div>
                                                                                {index === 0 ?
                                                                                    <p className="mb-0 text-muted">Первоначальная сума ордера</p>
                                                                                :
                                                                                    <p className="mb-0 text-muted">Изменено - Адмін ID{item.adminId}</p>
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                    <li className="timeline-item timeline-item-transparent ps-4" >
                                                                        <span className={`timeline-point timeline-point-info`}></span>
                                                                        <div className="timeline-event">
                                                                            <div className="timeline-header">
                                                                                <h6 className="mb-0 text-info fw-bold">Сума: {data.amount}{' '}{data.currency}</h6>
                                                                                <small className="text-muted">
                                                                                    сейчас
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </>
                                                    :
                                                        <button className="btn btn-sm btn-outline-secondary waves-effect mt-2 mb-4" onClick={() => setHistoryShow(true)}>
                                                            <i className="ti ti-history me-md-1 me-0"></i>
                                                            <span className="align-middle d-md-inline-block">История корректировок</span>
                                                        </button>
                                                    }
                                                </>
                                            :
                                                <li className="d-flex mb-3">
                                                    <div className="avatar flex-shrink-0 me-3">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="ti ti-abacus ti-sm"></i></span>
                                                    </div>
                                                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                        <h6 className="mb-0 text-muted">Сумма:</h6>
                                                        <div className="user-progress d-flex align-items-center gap-3">
                                                            <div className="d-flex align-items-center gap-1">
                                                                <strong className="text-modal text-uppercase">{data.amount}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-coins ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Валюта</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{data.currency}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-arrows-exchange ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Направление</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{getDirection(data.direction)}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-calculator ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Курс:</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{data.offer.price}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-file-text ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Статус</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{getStatus(data.status)}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-credit-card ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Метод оплати:</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{data.offer.payMethod}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-calendar ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Дата создания:</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">{moment(data.createdAt).format('DD.MM.YYYY, HH:mm')}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3">
                                                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-credit-card ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Оффер</h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase">id{data.offerId}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12">
                                <div className="card h-100">
                                    <div className="card-body pb-0" style={{position: 'relative'}}>
                                        <ul className="p-0 m-0">
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3 my-auto">
                                                    <span className="avatar-initial rounded bg-label-success"><i className="ti ti-user ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Владелец сделки:</h6><br/>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase text-success">{data.offerOwner.nickname}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="avatar flex-shrink-0 me-3 my-auto">
                                                    <span className="avatar-initial rounded bg-label-primary"><i className="ti ti-user ti-sm"></i></span>
                                                </div>
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted">Участник сделки:</h6><br/>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <strong className="text-modal text-uppercase text-primary">{data.orderOwner.nickname}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {data.offer.description && 
                                                <li className="d-flex mb-3">
                                                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                        <h6 className="mb-0 text-muted w-100">Комментарий:</h6>
                                                        <div className="user-progress d-flex align-items-center gap-3 comment w-100">
                                                            <div className="d-flex align-items-center gap-1">
                                                                <strong className="text-modal">{data.offer.description}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                            <hr variant="middle" sx={{ mb: 2, opacity: 0.4, backgroundColor: '#b6bee3' }} /> 
                                            <li className="d-flex mb-1">
                                                <h6 className="text-modal text-uppercase">Подтверждение:</h6>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted w-100">
                                                        {getDirectionConfirmed(data.offerOwner.id) === true ? "Получения средств:" : "Перевод средств:"}
                                                    </h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <span className='text-success'>{data.offerOwner.nickname}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="avatar flex-shrink-0 ms-auto my-auto">
                                                    {data.offerOwnerConfirmed === true ? 
                                                        <span className="avatar-initial rounded bg-label-success">
                                                            <i className="ti ti-circle-check ti-sm"></i>
                                                        </span>
                                                        :
                                                        <span className="avatar-initial rounded bg-label-danger">
                                                            <i className="ti ti-xbox-x ti-sm"></i>
                                                        </span>
                                                    }
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3">
                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                    <h6 className="mb-0 text-muted w-100">
                                                        {getDirectionConfirmed(data.orderOwner.id) === true ? "Получения средств:" : "Перевод средств:"}
                                                    </h6>
                                                    <div className="user-progress d-flex align-items-center gap-3">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <span className='text-primary'>{data.orderOwner.nickname}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="avatar flex-shrink-0 ms-auto my-auto">
                                                    {data.orderOwnerConfirmed === true ? 
                                                        <span className="avatar-initial rounded bg-label-success">
                                                            <i className="ti ti-circle-check ti-sm"></i>
                                                        </span>
                                                        :
                                                        <span className="avatar-initial rounded bg-label-danger">
                                                            <i className="ti ti-xbox-x ti-sm"></i>
                                                        </span>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-end mt-5 pb-0">
                                <button type="reset" 
                                    className="btn btn-label-secondary btn-reset waves-effect" 
                                    onClick = {() => modalClose()}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalOrder;