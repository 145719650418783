import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import Header from '../../components/Header'

export default function VerificationPage() {

    return (
        <>
            <Header/>
            <div className="content-wrapper vh-100" style={{marginLeft: '260px'}}>
                <div className="container-xxl flex-grow-1 pt-3">
                    <h3 className="mt-3 mb-5">Верификация пользователей</h3>
                    <div className="row">
                        <div className="col-md-6 col-xl-6">
                            <div className="card bg-info text-white mb-3">
                                {/* <div className="card-header">Верификация </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-8">
                                            <h5 className="card-title text-white">Верификация в Telegram</h5>
                                            <p className="card-text">Пока что заявки на верификацию пользователей приходят в телеграм!</p>
                                        </div>
                                        <div className="col-4">
                                            <img src={require('../../img/telegram.png')} className='telega-img'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    