import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/ru';

import { getArbitrageByID, setReadedChat } from '../../../features/MessangerSlice';

moment().locale('ru');

export default function ChatList() {

    const dispatch = useDispatch()

    const arbitrations = useSelector((state) => state.messenger.arbitrations)
    const activeChat = useSelector((state) => state.messenger.activeChat)
    const unreadedMessages = useSelector((state) => state.messenger.unreadedMessages)

    const [filteredList, setFilteredList] = React.useState(false);

    const renderActiveClass = (id) => {
        if ( activeChat && activeChat.id === id) {
            return 'open';
        } else {
            return 'border';
        }
    };

    const filterChatUsers = (event) => {
        const query = event.target.value;
        var updatedList = [...arbitrations];
        updatedList = updatedList.filter((item) => {
          var name = item.firstUser.nickname + item.secondUser.nickname + item.orderId;
            return name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }); 
        setFilteredList(updatedList);
    }

    const amountMessage = (id) => {
        let amount = unreadedMessages.filter( (step) => {
            return (step.includes(id))
        })
        return amount.length
    }

    const setReadedMessage = (itemId) => {
        let array = unreadedMessages.filter( (conv) => {
            return (conv !== itemId)
        })
        dispatch(setReadedChat(array))
    }
    
    return (
        <div className="col-3 app-chat-contacts app-sidebar flex-grow-0 overflow-hidden h-100">
            <div className="sidebar-header">
                <div className="d-flex align-items-center me-3 me-lg-0">
                    <div className="flex-grow-1 input-group input-group-merge rounded-pill">
                        <span className="input-group-text" >
                            <i className="ti ti-search"></i>
                        </span>
                        <input type="text" 
                            className="form-control chat-search-input" 
                            placeholder="Поиск..."
                            onChange={(event) => filterChatUsers(event)}
                        />
                    </div>
                </div>
                <i className="ti ti-x cursor-pointer d-lg-none d-block position-absolute mt-2 me-1 top-0 end-0"></i>
            </div>
            <div className="sidebar-body ps ps--active-y">
                <div className="chat-contact-list-item-title">
                    <h5 className="text-primary mb-0 px-4 pt-3 pb-2">Активные арбитражи</h5>
                </div>
                <ul className="list-unstyled chat-contact-list">
                    {filteredList ? (
                            filteredList.map( (item, index) => {
                                return (
                                    <li 
                                        className={`chat-contact-list-item ${renderActiveClass(item.id)}`} 
                                        key={index} 
                                        onClick={() => dispatch(getArbitrageByID(item.id))}
                                    >
                                        <a className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar avatar-text">
                                                <span className="avatar-initial rounded-circle bg-label-success">
                                                    {item.firstUser.nickname.charAt(0)}{item.secondUser.nickname.charAt(0)}
                                                </span>
                                            </div>
                                            <div className="chat-contact-info flex-grow-1 ms-2">
                                                <h6 className="chat-contact-name text-truncate m-0">
                                                    <span className='text-success'>{item.firstUser.nickname} </span> 
                                                    &&
                                                    <span className='text-primary'> {item.secondUser.nickname}</span> 
                                                </h6>
                                                <span className="chat-contact-status text-muted text-truncate mb-0" style={{fontSize: '12px'}}>
                                                    Oрдер {item.orderId} / {' '}
                                                </span>
                                                <small className="chat-contact-status text-muted text-truncate mb-0" style={{fontSize: '12px'}}>
                                                    {moment(item.createdAt).startOf('minute').fromNow()}
                                                </small>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })
                    ) : (
                        arbitrations && arbitrations.map( (item, index) => {
                            return (
                                <li 
                                    className={`chat-contact-list-item ${renderActiveClass(item.id)}`} 
                                    key={index} 
                                    onClick={() => { 
                                        dispatch(getArbitrageByID(item.id)); 
                                        if (unreadedMessages && unreadedMessages.includes(item.conversationId)) {
                                            setReadedMessage(item.conversationId);
                                        }
                                    }}
                                >
                                    <a className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar avatar-text">
                                            <span className='avatar-initial rounded-circle bg-label-success'>
                                                {item.firstUser.nickname.charAt(0)}{item.secondUser.nickname.charAt(0)}
                                            </span>
                                        </div>
                                        <div className="chat-contact-info flex-grow-1 ms-2">
                                            <h6 className='chat-contact-name text-truncate m-0'>
                                                {unreadedMessages.includes(item.conversationId) ? 
                                                    <>
                                                        <strong className='text-success fw-bold'>{item.firstUser.nickname} </strong> 
                                                        &&
                                                        <strong className='text-primary fw-bold'> {item.secondUser.nickname}</strong> 
                                                    </>
                                                :
                                                    <>
                                                        <span className='text-success'>{item.firstUser.nickname} </span> 
                                                        &&
                                                        <span className='text-primary '> {item.secondUser.nickname}</span> 
                                                    </>
                                                }
                                            </h6>
                                            <span className="chat-contact-status text-muted text-truncate mb-0" style={{fontSize: '12px'}}>
                                                Oрдер {item.orderId} / {' '}
                                            </span>
                                            <small className="chat-contact-status text-muted text-truncate mb-0" style={{fontSize: '12px'}}>
                                                {moment(item.createdAt).startOf('minute').fromNow()}
                                            </small>
                                        </div>
                                        {unreadedMessages && unreadedMessages.includes(item.conversationId) &&
                                            <span className="badge badge-center rounded-pill bg-primary ms-3">
                                                {amountMessage(item.conversationId)}
                                            </span>
                                        }
                                    </a>
                                </li>
                            )
                        })
                    )}
                </ul>
            </div>
        </div>
    );
}   