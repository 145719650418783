import { configureStore } from '@reduxjs/toolkit';

import AdminSlice from '../features/AdminSlice';
import ChatsSlice from '../features/ChatsSlice';
import MessangerSlice from '../features/MessangerSlice';
import VerificationSlice from '../features/VerificationSlice';

export const store = configureStore({
    reducer: {
        admin : AdminSlice,
        chats: ChatsSlice,
        messenger: MessangerSlice,
        verification: VerificationSlice,
    }
})