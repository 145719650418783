import React, { useRef, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { isEmpty } from 'lodash';
import { WebSocketContext } from '../../../utils/WebSocket';

import ChatHeader from './ChatHeader'

import { setNewMessage, pullMyMessageToChat, setActiveChat } from '../../../features/MessangerSlice';

export default function ChatList({ archive, data }) {

    const ws = useContext(WebSocketContext);

    const dispatch = useDispatch()

    const activeChat = useSelector((state) => state.messenger.activeChat)
    const loading = useSelector((state) => state.chats.loading)
    const newMessage = useSelector((state) => state.messenger.newMessage)

    const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			const scroll = messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;
			messagesEndRef.current.scrollTo({
				top: scroll,
				behavior: "smooth"
			})	
		}
		// if (conversationId && setReaded) {
		// 	setReaded(+conversationId);
		// }
		return;
	}

	useEffect(() => {
		scrollToBottom()
	}, [activeChat && activeChat.conversation.messages, loading]);

	useEffect(() => {
		if (archive === true) {
            dispatch(setActiveChat(data))
        }
	}, []);

	// useEffect(() => {
	// 	if (userIsTyping.typing === true) {
	// 		scrollToBottom()
	// 	}
	// }, [userIsTyping.typing]);

    const sendMessage = () => {
        if (isEmpty(newMessage)) { 
            return;
        } else {
            ws.sendMessage(activeChat.conversationId, activeChat.adminId, newMessage);
        }
    }

    return (
        <div className={`${archive === true ? 'col-9' : 'col-6'} app-chat-history bg-body h-100`}>
            <div className="chat-history-wrapper" style={{paddingBottom: '10px'}}>
                {activeChat && <ChatHeader/>}
                <div ref={messagesEndRef} className="chat-history-body bg-body ps ps--active-y chat_container h-100">
                    {loading === true ? (
                        <button type="submit" className="btn btn-label-primary btn-lg d-grid waves-effect waves-light my-3 mx-auto">
                            <span>
                                <span className="spinner-grow me-1" role="status" aria-hidden="true"></span> 
                                Загрузка...
                            </span>
                        </button>
                    ) : (
                        activeChat ? (
                            <ul className="list-unstyled chat-history">
                                {activeChat.conversation.messages && activeChat.conversation.messages.map( (item, index) => (
                                    item.senderId ? (
                                        <li className={`chat-message ${item.senderId === activeChat.adminId && 'chat-message-right'}`} key={index}>
                                            {item.senderId === activeChat.conversation.firstUser.id && 
                                                <div className="d-flex overflow-hidden">
                                                    <div className="user-avatar flex-shrink-0 me-3">
                                                        <div className="flex-shrink-0 avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-success">{activeChat.conversation.firstUser.nickname.charAt(0)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="chat-message-wrapper flex-grow-1">
                                                        <div className="chat-message-text" style={{backgroundColor: 'rgba(123, 236, 153, 0.36)'}}>
                                                            <p className="mb-0">{item.text}</p>
                                                        </div>
                                                        <div className="text-muted mt-1">
                                                            <small>{moment(item.createdAt).format('HH:mm, DD.MM.YYYY')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {item.senderId === activeChat.conversation.secondUser.id && 
                                                <div className="d-flex overflow-hidden">
                                                    <div className="user-avatar flex-shrink-0 me-3">
                                                        <div className="flex-shrink-0 avatar">
                                                            <span className="avatar-initial rounded-circle bg-label-primary">{activeChat.conversation.secondUser.nickname.charAt(0)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="chat-message-wrapper flex-grow-1">
                                                        <div className="chat-message-text" style={{backgroundColor: 'rgba(113, 113, 232, 0.36)'}}>
                                                            <p className="mb-0">{item.text}</p>
                                                        </div>
                                                        <div className="text-muted mt-1">
                                                            <small>{moment(item.createdAt).format('HH:mm, DD.MM.YYYY')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {item.senderId === activeChat.adminId && 
                                                <div className="d-flex overflow-hidden">
                                                    <div className="chat-message-wrapper flex-grow-1">
                                                        <div className="chat-message-text">
                                                            <p className="mb-0">{item.text}</p>
                                                        </div>
                                                        <div className="text-end text-muted mt-1">
                                                            {item.readed === true ? 
                                                                <i className="ti ti-checks ti-xs me-1 text-success"></i>
                                                            :
                                                                <i className="ti ti-checks ti-xs me-1 text-secondary"></i>
                                                            }
                                                            <small>{moment(item.createdAt).format('HH:mm, DD.MM.YYYY')}</small>
                                                        </div>
                                                    </div>
                                                    <div className="user-avatar flex-shrink-0 ms-3">
                                                        <div className="avatar avatar-busy avatar-sm">
                                                            <img src={require('../../../img/13.png')} alt="Avatar" className="rounded-circle"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                    ) : (
                                        <li className="chat-message" style={{ justifyContent: 'center'}} key={index}>
                                            <div className="d-flex overflow-hidden">
                                                <div className="chat-message-wrapper flex-grow-1">
                                                    <div className="chat-message-text" style={{backgroundColor: '#2f3349'}}>
                                                        <p className="mb-0">{item.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                ))}
                            </ul>
                        ) : (
                            <ul className="list-unstyled chat-history">
                                <li className="chat-message chat-message-right" style={{justifyContent: 'center', marginTop: '200px'}}>
                                    <div className="d-flex overflow-hidden">
                                        <div className="chat-message-wrapper flex-grow-1">
                                            <div className="chat-message-text">
                                                <p className="mb-0 text-white">Выберите чат для начала работы!</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        )
                    )}
                </div>
                {archive !== true && activeChat &&
                    <div className="chat-history-footer shadow-sm ">
                        <form onSubmit={(e) => e.preventDefault() } className="form-send-message d-flex justify-content-between align-items-center">
                            <input 
                                className="form-control message-input border-0 me-3 shadow-none" 
                                placeholder="Введите сообщение..."
                                onChange={async (e) => { await dispatch(setNewMessage(e.target.value))}}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        sendMessage();
                                        dispatch(setNewMessage(''));
                                    }
                                }}
                                value={newMessage}
                            />
                            <div className="message-actions d-flex align-items-center">
                                <button 
                                    className="btn btn-primary d-flex send-msg-btn waves-effect waves-light"
                                    onClick={() => {sendMessage(); dispatch(setNewMessage(''))}}
                                >
                                    <i className="ti ti-send me-md-1 me-0"></i>
                                    <span className="align-middle d-md-inline-block d-none">Отправить</span>
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
}   