import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import SimpleModal from '../../../components/modals/SimpleModal'
import ModalOrder from '../../../components/modals/ModalOrder'
import ModalBalance from '../../../components/modals/ModalBalance'

import { setNewAmount, sendNewAmount, closeArbitration, getOrderById, setDataOrder } from '../../../features/ChatsSlice';

export default function ChatSettings({ archive }) {

    const dispatch = useDispatch()

    const activeChat = useSelector((state) => state.messenger.activeChat)
    const dataOrder = useSelector((state) => state.chats.dataOrder)
    const amount = useSelector((state) => state.chats.amount)

    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalOrder, setModalOrder] = useState(false);
    const [modalBalance, setModalBalance] = useState(false);
    const [cardShow, setCardShow] = useState(false);
    const [errorInput, setErrorInput] = useState(false);
    const [closeArbitrage, setCloseArbitrage] = useState(false);
    const [changeArbitrage, setChangeArbitrage] = useState(false);

    const changeArbitrageSumm = () => {
        let data = {
            'amount': amount,
            'orderId': activeChat.orderId
        }
        dispatch(sendNewAmount(data))
        dispatch(setNewAmount(''))
        setCardShow(false)
    }

    const inputError = () => {
        if (amount && !isEmpty(amount)) {
            setModalShow(true); 
            setChangeArbitrage(true)
            setErrorInput(false) 
        } else {
            setErrorInput(true)
        }
    }

    const closeActiveArbitrage = () => {
        let arbitrationId = {
            "arbitrationId": activeChat.id,
        }
        dispatch(closeArbitration(arbitrationId));
    }

    const getOrder = () => {
        setLoading(true);
        let info = {
            "userId": activeChat.conversation.firstUser.id,
            'orderId': activeChat.orderId,
        }
        dispatch(getOrderById(info));
        setLoading(false);
    }

    // const getUsersBalance = () => {
    //     let info = {
    //         "userId": activeChat.metadata.orderOwnerId,
    //         'orderId': activeChat.metadata.orderId
    //     }
    //     dispatch(getBalance(info))
    // }

    return (
        activeChat &&
        <>
            <div className="col-3 app-chat-contacts app-sidebar flex-grow-0 overflow-hidden h-100">
                <div className=" ps ps--active-y">
                    <div className="chat-contact-list-item-title ms-3 mt-3 mb-4">
                        <h5 className="text-white">Информация</h5>
                    </div>
                    <div className="ms-3 me-lg-0">
                        <button className="btn btn-label-info waves-effect my-2" onClick={() => { getOrder(); setModalShow(true); setModalOrder(true)}}>
                            <i className="ti ti-file-dots me-md-1 me-0"></i>
                            <span className="align-middle d-md-inline-block">Посмотреть ордер</span>
                        </button>
                        <button className="btn btn-label-info waves-effect my-2" 
                            onClick={() => { 
                                // getUsersBalance(); 
                                setModalShow(true); setModalBalance(true)
                            }}
                        >
                            <i className="ti ti-coins me-md-1 me-0"></i>
                            <span className="align-middle d-md-inline-block">Посмотреть балансы</span>
                        </button>
                    </div>
                    {archive !==true &&
                        <>
                            <hr/>
                            <div className="chat-contact-list-item-title ms-3 mt-3 mb-4">
                                <h5 className="text-white">Действия</h5>
                            </div>
                            <div className="ms-3 me-lg-0">
                                <button className="btn btn-outline-success waves-effect mx-auto my-2 px-3" onClick={() => setCardShow(true)}>
                                    <i className="ti ti-edit me-md-1 me-0"></i>
                                    <span className="align-middle d-md-inline-block">Изменить сумму ордера</span>
                                </button>
                                {cardShow === true &&
                                    <div className='card px-3 py-2 me-3 my-4 border'>
                                        <div>
                                            <label className="form-label">Сумма</label>
                                            <input type="number" 
                                                value={amount}
                                                className="form-control" 
                                                placeholder="750"
                                                onChange={(e) => dispatch(setNewAmount(e.target.value))}
                                            />
                                            <div className={`form-text ${errorInput === true && 'text-danger'}`}>Введите новую сумму ордера.</div>
                                        </div>
                                        <div className='d-flex mt-2'>
                                            <button className="btn btn-sm rounded-pill btn-label-success waves-effect mx-auto my-2 px-2" onClick={() => inputError()}>
                                                <span className="align-middle d-md-inline-block">Подтвердить</span>
                                            </button>
                                            <button className="btn btn-sm rounded-pill btn-label-dark waves-effect mx-auto my-2 px-2" onClick={() => setCardShow(false)}>
                                                <span className="align-middle d-md-inline-block">Отменить</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <button className="btn btn-outline-secondary waves-effect mx-auto my-2" onClick={() => { setModalShow(true); setCloseArbitrage(true) }}>
                                    <i className="ti ti-square-check me-md-1 me-0"></i>
                                    <span className="align-middle d-md-inline-block">Закрыть спор</span>
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
            {changeArbitrage === true &&
                <SimpleModal
                    title={`Изменить сумму ордера №${activeChat.orderId}?`}
                    text={`Вы уверены, что хотите изменить сумму на ${amount}?`}
                    btnSuccess='Изменить сумму'
                    btnClose='Отменить'
                    onClickFunct={() => changeArbitrageSumm()}
                    show={modalShow}
                    modalClose={() => {setModalShow(false); setChangeArbitrage(false); }}
                />
            }
            {closeArbitrage === true &&
                <SimpleModal
                    title={`Закрыть спор №${activeChat.id}?`}
                    text={`Вы уверены, что хотите закрыть спор по ордеру №${activeChat.orderId}?`}
                    btnSuccess='Закрыть спор'
                    btnClose='Отменить'
                    onClickFunct={() => closeActiveArbitrage()}
                    show={modalShow}
                    modalClose={() => { setModalShow(false); setCloseArbitrage(false); }}
                />
            }
            {modalOrder === true && dataOrder && 
                <ModalOrder
                    data={dataOrder}
                    show={modalShow}
                    modalClose={() => { setModalShow(false); setModalOrder(false); dispatch(setDataOrder(false));}}
                />
            }
            {modalBalance === true && 
            // dataBalance &&
                <ModalBalance
                    // data={dataBalance}
                    show={modalShow}
                    modalClose={() => { setModalShow(false); setModalBalance(false); }}
                />
            }
        </>
        
    );
}   